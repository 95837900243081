.App {
  background-color: #12203c;
  color: white;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(1px + 2vmin);

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;

}

.App-link {
  color: #61dafb;
}
